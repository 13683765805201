.tableContainer:global(.MuiTableContainer-root) {
  overflow-x: inherit;

  .table {
    .tableHead > tr > th {
      font-weight: bold;
      width: 150px;
    }
  
    .tableBody {
      .tableRow {
        .editIcon {
          cursor: pointer;
        }

        .img {
          width: 150px;
          height: 150px;
          object-fit: cover;
        }

        td {
          width: 150px;
        }

        &:last-child td,
        &:last-child th { 
          border: 0
        }
      }
    }
  }
}
