.container {
  width: 47.3vw;
  font-family: 'Inter', sans-serif;
  color: white;

  .basicInfo {
    height: 300px;
    background-color: #009FE3;

    img {
      position: relative;
      height: 167px;
      width: 167px;
      border-radius: 50%;
      outline: 4px solid white;
      outline-offset: -1px;
      top: 70px;
      left: 40px;
    }

    .role {
      position: relative;
      text-align: left;
      font: normal normal medium 12px/18px Inter;
      height: 15px;
      top: -50px;
      left: 230px;
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }

    .name {
      position: relative;
      text-align: left;
      font: normal normal bold 24px/30px Inter;
      height: 29px;
      top: -50px;
      left: 230px;
    }
  }

  .about {
    padding: 15px;

    .title {
      color: #009FE3;
      text-align: left;
      font: normal normal 600 36px/44px Inter;
      height: 44px;
    }

    .splitSection {
      display: flex;
      flex-direction: row;

      .content {
        color: #999999;
        width:50%;
      }

      img {
        width: 200px;
        border-radius: 20px;
        padding-left: 10px;
        height: fit-content;
      }
    }
  }
}