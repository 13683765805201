.container {
  width: 47.3vw;
  font-family: 'Inter', sans-serif;
  padding: 0 25px;

  .date {
    text-align: center;
    font: normal normal medium 12px/18px Inter;
    letter-spacing: 0.6px;
    color: #009FE3;
    text-transform: uppercase;
    height: 15px;
  }

  .title {
    text-align: center;
    font: normal normal 600 36px/44px Inter;
    color: #000000;
  }

  .thumbnail {
    height: 250px;
    width: 100%;
  }

  .content {
    margin-top: 10px;
    text-align: left;
    font: normal normal normal 14px/18px Inter;
    letter-spacing: 0px;
    color: #999999;
  }
}