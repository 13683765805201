.container {
  width: 47.3vw;
  font-family: 'Inter', sans-serif;
  padding: 5px;

  .slider {
    position: relative;
  }

  .location {
    margin-top: 10px;
    color: #A3ACB1;
    font-weight: bold;

    .icon {
      margin: -8px -2px 0px 0px;
    }
  }

  .projectType {
    margin-top: 15px;
    color: #00243F;
    font-weight: bold;
  }

  .description {
    text-align: left;
    color: #999999;
  }
}