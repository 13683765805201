.home {
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 90vh;

  .drawerContainer {
    min-width: 250px;
  }

  .drawer > :global(.MuiPaper-root) {
    margin-top: 70px;
  }

  .contentContainer {
    width: 100%;
    padding: 10px 0px;
    overflow: auto;
    height: 90vh;
  }
}
