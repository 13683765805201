#toolbar {
    border-radius: 8px;
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 10px;
    row-gap: 15px;
}

#toolbar-grp>* {
    margin-right: 10px;
    cursor: pointer;
}

#toolbar-grp {
    margin: 0 10px;
}

.ql-container {
    font-family: 'Quicksand', sans-serif;
    border-radius: 8px;
    min-height: 150px;
    height: fit-content;
}

.ql-snow {
    font-family: 'Quicksand', sans-serif !important;
}