.dialog {
  .appBar {
    position: relative;
  }

}

.title {
  flex-grow: 1;
  margin-left: 4px;
}