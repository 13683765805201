.container {
  min-height: 100vh;
  display: grid;
  place-items: center;
  .card {
    background: #212121;
    color: #fff;
    padding: 8% 2%;
    height: 450px;
    width: 500px;
    border-radius: 25px;

    .title {
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;
    }

    .button {
      margin-top: 20px;
      flex-grow: 1;
      height: 40px;
      width: 10px;
      border: 8px;
    }
  }
}